import React from "react";
import { Link } from "react-router-dom";

export default function HomeComponent() {
  return (
    <div style={{ fontSize: "26px", paddingTop: "5px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div>
          <span style={{ color: "red " }}>x,xx,xxx+</span>{" "}
          <span className="purple"> online now</span>
        </div>
        <div style={{ fontSize: "44px", fontWeight: "800" }} className="purple">
          <i>Magllenster</i>
        </div>
        <div>
          <div>
            <Link to={"/login"}>
              <button
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#4f3eed",
                  border: "none",
                  color: "#fff",
                  fontSize: "24px",
                  padding: "10px 15px",
                }}
              >
                Login/SignUp
              </button>
            </Link>
          </div>
          <div>
            <Link to={"/mag"}>
              <button
                style={{
                  background: "none",
                  border: "none",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
                className="purple"
              >
                Want To Go Anonymous?
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          //justifyContent: "space-around",
          flexWrap: "wrap",
          paddingTop: "40px",
        }}
      >
        <div style={{ width: "40%", marginLeft: "100px" }}>
          <div
            style={{
              width: "50%",
              fontSize: "30px",
              fontWeight: "600",
              color: "#504f57",
              letterSpacing: "2px",
            }}
          >
            Best Stranger Meeting Platform In The World
          </div>
          <div
            style={{ width: "40%", paddingTop: "30px", fontSize: "22px" }}
            className="purple"
          >
            How is it different From Any Other Platform?
          </div>
        </div>
        <div>
          <img
            src="homeImage.jpg"
            alt="Video Chat"
            style={{ height: "500px" }}
          />
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <div className="purple" style={{ fontSize: "28px", fontWeight: "600" }}>
          No Signup Required
        </div>
        <div>
          <Link to={"./mag"}>
            <button
              style={{
                borderRadius: "8px",
                backgroundColor: "#4f3eed",
                border: "none",
                color: "#fff",
                fontSize: "22px",
                padding: "10px 15px",
                marginTop: "10px",
              }}
            >
              Go Anonymous
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

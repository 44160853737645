import React from "react";
import { Link } from "react-router-dom";

export default function MagComponent() {
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        style={{
          flex: "1 1 100%",
          border: "2px solid #4f3eed",
          borderRadius: "4px",
          marginRight: "50px",
          textAlign: "center  ",
          position: "relative",
        }}
      >
        <p style={{ fontSize: "26px", fontWeight: "600" }} className="purple">
          List of Your Maggers
        </p>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <div style={{ color: "orange", fontSize: "24px" }}>No Maggers</div>
          <Link to="/meet">
            <button
              style={{
                borderRadius: "8px",
                backgroundColor: "#4f3eed",
                border: "none",
                color: "#fff",
                fontSize: "22px",
                padding: "10px 15px",
                marginTop: "10px",
              }}
            >
              Start Magging
            </button>
          </Link>
        </div>
      </div>
      <div
        style={{
          flex: "1 1 200%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              border: "3px solid #4f3eed",
              padding: "15px 20px",
              fontSize: "42px",
              borderRadius: "4px",
            }}
          >
            <span style={{ color: "orange" }}>Hello World!</span>
          </div>
          <div>
            <img
              src="profileIcon.png"
              alt="profile"
              style={{ height: "60px" }}
            />
          </div>
        </div>
        <div
          style={{
            width: "70%",
            border: "3px solid #4f3eed",
            padding: "15px",
            fontSize: "20px",
            borderRadius: "4px",
          }}
        >
          <div>
            What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the
            leap into electronic typesetting, remaining essentially unchanged.
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <div>
            <Link to="/meet">
              <button
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#4f3eed",
                  border: "none",
                  color: "#fff",
                  fontSize: "30px",
                  padding: "15px 20px",
                  marginTop: "10px",
                }}
              >
                Start Magging
              </button>
            </Link>
          </div>
          <div style={{ color: "orange" }}>Suggest Feedback!</div>
        </div>
      </div>
    </div>
  );
}

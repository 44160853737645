import React from "react";

export default function MeetComponent() {
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        style={{ flexGrow: "1", border: "2px solid grey", marginRight: "50px" }}
      >
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        > */}
        <div style={{ height: "50%", width: "100%" }}>
          <img
            src="vc1.webp"
            alt="vc1"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        <div style={{ height: "50%", width: "100%" }}>
          <img
            src="vc2.jpeg"
            alt="vc2"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        {/* <img src="vc.jpg" alt="vc" style={{ height: "100%", width: "100%" }} /> */}
        {/* </div> */}
      </div>
      <div style={{ flexGrow: "10" }}>
        <div
          style={{
            height: "100%",
            border: "2px solid grey",
            display: "inline-block",
          }}
        >
          <img src=" chat.jpg" alt="chat" style={{ height: "100%" }} />
        </div>
      </div>
    </div>
  );
}

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeComponent from "./components/HomeComponent/HomeComponent";
import LoginComponent from "./components/LoginComponent/LoginComponent";
import MeetComponent from "./components/MeetComponent/MeetComponent";
import "./App.css";
import MagComponent from "./components/MagComponent/MagComponent";

function App() {
  return (
    <div style={{ padding: "20px", height: "90vh" }} className="tiltFont">
      <Router>
        <Routes>
          <Route exact path="/" element={<HomeComponent />} />
          <Route exact path="/login" element={<LoginComponent />} />
          <Route exact path="/meet" element={<MeetComponent />} />
          <Route exact path="/mag" element={<MagComponent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function LoginComponent() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  function handleSignIn(e) {
    e.preventDefault();
    if (
      name === "arjun" &&
      email === "arjun@gmail.com" &&
      password === "pass"
    ) {
      navigate("/mag");
    } else alert("Invalid credentials");
  }

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          // border: "1px solid grey",
          // borderRadius: "4px",
          padding: "50px 30px",
        }}
      >
        <div>
          <img src="logo.jpg" alt="logo" />
        </div>
        <div>
          <form>
            <div
              style={{
                padding: "10px",
                fontSize: "26px",
                display: "flex",
                justifyContent: "space-between",
              }}
              className="purple"
            >
              <span style={{ marginRight: "15px" }}>Username</span>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  fontSize: "22px",
                  borderRadius: "4px",
                  padding: "4px",
                }}
              />
            </div>
            <div
              style={{
                padding: "10px",
                fontSize: "26px",
                display: "flex",
                justifyContent: "space-between",
              }}
              className="purple"
            >
              <span style={{ marginRight: "15px" }}>Email</span>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  fontSize: "22px",
                  borderRadius: "4px",
                  padding: "4px",
                }}
              />
            </div>
            <div
              style={{
                padding: "10px",
                fontSize: "26px",
                display: "flex",
                justifyContent: "space-between",
              }}
              className="purple"
            >
              <span style={{ marginRight: "15px" }}>Password</span>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  fontSize: "22px",
                  borderRadius: "4px",
                  padding: "4px",
                }}
              />
            </div>

            <div>
              <button
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#4f3eed",
                  border: "none",
                  color: "#fff",
                  fontSize: "22px",
                  padding: "10px",
                  margin: "30px 0",
                }}
              >
                <input
                  type="submit"
                  value="Sign In"
                  onClick={(e) => handleSignIn(e)}
                  style={{
                    background: "none",
                    border: "none",
                    color: "#fff",
                    fontSize: "18px",
                  }}
                />
              </button>
            </div>
          </form>
          <span style={{ color: "orange" }}>Already have an account?</span>
          <Link to="/login">
            <button
              style={{
                borderRadius: "8px",
                backgroundColor: "#4f3eed",
                border: "none",
                color: "#fff",
                fontSize: "22px",
                padding: "10px",
                marginLeft: "30px",
              }}
            >
              Log In
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
